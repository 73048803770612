.add-product {
    height: 100vh;
    overflow: hidden;
    background-color: white;

    .add-product__content {
      padding: 2rem;
      max-width: 60%;
    }
  
    .ant-btn {
      border-radius: 6px !important;
      overflow: hidden;
    }
  
    .step-slider {
      display: none;
    }
  
    .ant-form-vertical .ant-form-item-row {
      width: 100%;
    }
    .ant-btn-primary {
      border-color: #0b5394;
      background: #0b5394;
      font-family: "Manrope";
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-align: center;
    }
  
    &__sider {
      border-right: 1px solid rgba(229, 231, 235, 1);
      color: #fff;
      line-height: 120px;
      background: #f9fafb;
  
      .border-red {
        .ant-steps-item-container {
          border-radius: 5px !important;
          border: 1px solid red !important;
        }
        .ant-steps-item-icon:hover {
          border: 1px solid red !important;
        }
        .ant-steps-icon {
          color: red;
        }
        .ant-steps-icon:hover {
          color: red !important;
        }
        .ant-steps-item-content {
          .ant-steps-item-title {
            color: red;
          }
          .ant-steps-item-title:hover {
            color: red !important;
          }
        }
      }
      .border-red:hover .ant-steps-item-icon {
        color: red !important;
        border: 1px solid red !important;
      }
      .border-red:hover .ant-steps-icon {
        color: red !important;
      }
    }
    .types {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 50px;
      row-gap: 20px;
      margin: 15px 0 30px;
    }
  
    .ip-type {
      .ant-form-item-label label {
        font-size: 17px;
      }
    }
    .ip-transfer {
      .ant-form-item-label label {
        font-size: 17px;
      }
    }
  
    &__header {
      background-color: white;
      color: black;
      border-bottom: 1px solid rgba(229, 231, 235, 1);
      padding: 0;
  
      .header-layout {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .left {
          display: flex;
          align-items: center;
          margin-left: 20px;
  
          .header-brand {
            margin-right: 13px;
          }
        }
  
        .right {
          display: flex;
          align-items: center;
          margin-right: 20px;
  
          .save-drafts {
            margin-left: 13px;
          }
        }
      }
    }
  
    &__content {
      color: black;
      background: #fff;
      overflow-y: scroll !important;
      overflow-x: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
  
      .content-header {
        display: flex;
        flex-direction: column;
        margin: 48px 80px 20px 80px;
  
        .steps {
          color: rgba(11, 83, 148, 1);
        }
  
        .description {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;
          color: #6b7280;
          text-align: inherit;
          max-width: 585px;
          width: 100%;
          word-break: break-word;
        }
      }
    }
    &__content::-webkit-scrollbar {
      display: block;
      width: 6px;
      background-color: #f5f5f5;
    }
    &__content::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: #0b5394;
    }
  
    &__footer {
      display: none;
    }
  }
  
  /*        STEPS STYLE       */
  .error-border {
    .ant-steps-item-process {
      .ant-steps-item-container {
        box-shadow: 0px 0px 0px 4px rgba(242, 140, 140, 0.25),
          0px 1px 2px 0px rgba(40, 16, 16, 0.1) !important;
        border-color: rgba(148, 11, 11, 0.6) !important;
        color: red !important;
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          color: red !important;
        }
        .ant-steps-item-title:hover {
          color: red;
        }
      }
    }
  }
  
  .deal-navigation-steps {
    // margin-top: 35px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  
    .deal-step {
      margin: 20px;
  
      .ant-steps-item-container {
        align-items: center;
        display: flex;
        border: 1px solid;
        width: 100%;
        height: 57px;
        border-radius: 5px;
        background-color: #fff;
        border-color: rgba(206, 219, 231, 1);
  
        .ant-steps-item-tail {
          top: 32px !important;
          left: 125px !important;
        }
  
        .ant-steps-item-content {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  
    .ant-steps-item-content {
      min-height: 40px !important;
  
      .ant-steps-item-title {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
  
      .ant-steps-item-description {
        padding: 0;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6b7280 !important;
      }
    }
  
    .ant-steps-item-icon {
      border-radius: 5px;
      margin-left: 10px;
    }
  
    .ant-steps-item-process {
      .ant-steps-item-container {
        box-shadow: 0px 0px 0px 4px rgba(140, 191, 242, 0.25),
          0px 1px 2px 0px rgba(16, 24, 40, 0.1);
        border-color: rgba(11, 83, 148, 0.6);
      }
    }
  
    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background-color: #f9fafb;
        border-color: transparent;
      }
    }
  }
  .deal-navigation-steps::-webkit-scrollbar {
    display: block;
    width: 6px;
    background-color: #f5f5f5;
  }
  .deal-navigation-steps::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #0b5394;
  }
  .confirm-modal .ant-modal-confirm-content {
    margin: 30px 0 0 0 !important;
    .save-draft-btn {
      height: 44px;
      border-radius: 6px;
      font-size: 15px;
    }
    .save-btn {
      height: 44px;
      border-radius: 6px;
      font-size: 15px;
      width: 100px;
    }
  }
  @media (max-width: 768px) {
    .add-product {
      &__sider {
        display: none;
      }
  
      .content-header {
        display: flex;
        flex-direction: column;
        margin: 20px;
      }
      .types {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
      }
    }
  }
  @media (max-width: 500px) {
    .add-product {
      .types {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 40px;
      }
    }
  }
  
  @media (max-width: 768px) {
    .add-product {
      &__sider {
        display: none;
      }
  
      .step-slider {
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 45px;
        align-items: center;
        border-bottom: 1px solid rgba(229, 231, 235, 1);
  
        .left-arrow {
          margin-left: 20px;
        }
  
        .right-arrow {
          margin-right: 20px;
        }
  
        .step-content {
          display: flex;
          align-items: center;
  
          .icon {
            margin-right: 10px;
            text-align: center;
            width: 33px;
            height: 33px;
            background-color: rgba(11, 83, 148, 1);
            font-family: Manrope;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: rgba(255, 255, 255, 1);
            padding-top: 6px;
            border-radius: 6px;
          }
        }
      }
  
      .ant-row {
        margin: 0;
      }
      .ant-form-item {
        margin: 20px 0 0 0 !important;
      }
      .dragger {
        margin: 20px 0 0 0 !important;
      }
  
      .content-header {
        display: flex;
        flex-direction: column;
        margin: 20px;
      }
  
      &__footer {
        display: block;
        padding: 0 !important;
  
        .right {
          display: flex;
          flex-direction: column;
  
          .preview {
            margin: 10px 20px 0 20px;
          }
  
          .save-drafts {
            margin: 10px 20px;
          }
        }
      }
  
      &__header {
        .header-layout {
          display: flex;
          justify-content: space-between;
          align-items: center;
  
          .left {
            display: flex;
            align-items: center;
            margin-left: 20px;
            margin-right: 20px;
            justify-content: space-between;
            width: 100%;
          }
  
          .right {
            display: flex;
            align-items: center;
            margin-right: 20px;
            display: none;
          }
        }
      }
    }
  }
  
  .ant-modal-confirm-body-wrapper {
    .ant-modal-confirm-body {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .anticon {
        font-size: 30px;
        margin: 0 0 10px 0 !important;
      }
    }
  
    .ant-modal-confirm-btns {
      width: 100%;
      display: flex;
      justify-content: center;
  
      .ant-btn-primary {
        border-color: #0b5394;
        background: #0b5394;
        font-family: "Manrope";
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0.01em;
        text-align: center;
      }
  
      .ant-btn {
        border-radius: 6px !important;
        width: 121px;
        height: 45px;
      }
    }
  }
  
  .show-hide-wrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
  
    .ant-upload-list-item {
      width: 100%;
      padding-bottom: 0 !important;
      margin-top: 0 !important;
      
      .ant-upload-list-item-info {
        padding: 10px;
      }
    }
    
  }