.product-listing-container {
    padding: 20px;
    text-align: center;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.product-card-image {
    height: 140px;
    object-fit: cover;
}

.compact-product-card {
    width: 100%;
    text-align: center;
    padding: 10px;
}

.floating-cart-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.cart-item-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
}

.cart-total {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
}



/* Floating Cart Container */
.floating-cart-container {
    position: fixed;
    bottom: 30px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Floating Cart Button */
.floating-cart-button {
    width: 60px;
    height: 60px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Cart Badge */
.cart-badge .ant-badge-count {
    font-size: 14px;
    background-color: red !important; 
    color: white !important;
    min-width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    right: 8px !important;
    bottom: 20px !important;
}
.floating-cart-container button {
    position:  relative !important;
    z-index: -1;
}