.loading-style{
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 150"><path fill="none" stroke="%231F44FF" stroke-width="15" stroke-linecap="round" stroke-dasharray="300 385" stroke-dashoffset="0" d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z"><animate attributeName="stroke-dashoffset" calcMode="spline" dur="2" values="685;-685" keySplines="0 0 1 1" repeatCount="indefinite"></animate></path></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12rem;
}

/* iframeStyle.css */
.iframe-container {
    position: relative;
    width: 100%;
    height: 1000px;
    /* overflow: scroll; */
}

.iframe-watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: 3rem;
    color: rgba(0, 0, 0, 0.15);
    font-weight: bold;
    pointer-events: none; /* Allows interaction with the iframe */
    user-select: none;
}

iframe {
    width: 100%;
    height: 100%;
    border: none;
}
