html, body{ 
	//min-height: 100vh;
	//overflow: auto;
}

body {
	//padding: 0 0 30px 0;
	/* background-color: #fafafa; */
	letter-spacing: -0.3px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.6;

	font-family: Manrope;
}

.ant-layout.ant-layout-has-sider {
	flex-direction: row;
}

.layout-dashboard {
	background: white !important;
	text-align: left;
	position: relative;
	overflow-x: hidden;
}

.sign-out-more svg {
	height: 2em !important;
    width: 2em;
    margin-left: 3px;
}


.time-font-style {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    line-height: 1.5715;
}

@media (min-width: 992px) {
	.layout-dashboard {
		overflow: auto;
	}
}

.layout-dashboard .ant-layout-sider.sider-primary {
	/* width: 250px;
    position: fixed;
    left: 0;
    z-index: 99;
    height: 100vh;
    overflow: auto;
    margin: 0;
    padding: 33px 20px; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0px;

	position: fixed;
	width: 300px !important;
	height: 1188px;
	left: 0px;
	top: 0px;

	/* Base/White */

	background: #ffffff;
	/* border-right-style: #e5e7eb; */
	border-right: 1px #e5e7eb solid;
}

.dvd_hg {
	color: red;
}

.layout-dashboard .ant-layout-sider.sider-primary .brand img {
	/* height: 17.47px; */
}

.deal_detal {
	background-color: blue;
	height: 100vh;
}

.layout-dashboard .ant-layout-sider.sider-primary hr {
	margin: 10px 0;
	width: 300px;
	border: none;
	height: 1px;
	background-color: #eaecf0;
}



.layout-dashboard .ant-layout-sider.sider-primary .brand {
	font-weight: 600;
	margin-bottom: 24px;
	margin-left: 16px;
}

.layout-dashboard .ant-layout-sider.sider-primary .brand span {
	vertical-align: middle;
	margin-left: 1px;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-layout-sider-children {
	min-height: auto;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.user_sidenav {
	height: 40px;
	width: 40px;
	margin-left: 20px;
	margin-top: 10px;
	border-radius: 20px;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline {
	border: none;
}

.layout-dashboard .ant-menu {
	background: transparent;
}

.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-inline
	.ant-menu-item,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-inline
	.ant-menu-submenu {
	margin: 0;
	overflow: visible;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu {
	padding: 0 !important;
	/* height: auto; */
	line-height: normal;
}

.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-inline
	.ant-menu-item:after,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-inline
	.ant-menu-submenu:after {
	display: none;
}

.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-item.ant-menu-item-selected,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-item.ant-menu-item:active,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-item
	.ant-menu-submenu-selected,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-item.ant-menu-submenu-title:active,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-item
	.ant-menu-submenu:active,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-submenu.ant-menu-item-selected,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-submenu.ant-menu-item:active,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-submenu
	.ant-menu-submenu-selected,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-submenu.ant-menu-submenu-title:active,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-submenu
	.ant-menu-submenu:active {
	background-color: transparent;
}

.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-item.ant-menu-item-selected
	.active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-submenu.ant-menu-item-selected
	.active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active {
	// background-color: #f9fafb;
	// color: #111827;
	font-weight: 500;
	font-size: 16px;
	font-family: "Manrope";
	font-style: normal;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a {
	padding: 10px 16px;
	// color: #6b7280;
	border-radius: 8px;
	font-weight: 500;
	font-size: 16px;
	font-family: "Manrope";
	font-style: normal;
	display: flex;
	align-items: center;
	white-space: nowrap;
}

.layout-dashboard
	.ant-layout-sider.sider-primary.ant-layout-sider-primary
	.ant-menu-item.ant-menu-item-selected
	.active
	.icon,
.layout-dashboard
	.ant-layout-sider.sider-primary.ant-layout-sider-primary
	.ant-menu-item
	.active
	.icon {
	background-color: transparent;
}

.icon-nav {
    margin-right: 10px;
    width: 35px;
    height: 35px;
}

.icon{
	&.unreaded {
        position: relative;

        &:after {
            content: "";
            background-color: #0B5394;
            border-radius: 50%;
            width: 7px;
            height: 7px;
            position: absolute;
            top: 6px;
            right: 7px;
        }
    }
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .icon,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .icon {
	display: inline-flex;
	width: 32px;
	height: 32px;
	// background-color: #fff;

	border-radius: 6px;
	justify-content: center;
	align-items: center;
	margin-right: 11px;
	vertical-align: middle;
}

.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-item.ant-menu-item-selected
	.active
	svg
	path,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-item
	.active
	svg
	path,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-submenu.ant-menu-item-selected
	.active
	svg
	path,
.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-submenu
	.active
	svg
	path {
	// fill: #fff;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item svg path,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu svg path {
	// fill: #bfbfbf;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a:hover,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a:hover {
	color: #141414;
	.icon-nav-longevity{
		color:#6b7280
	}
}

.layout-dashboard
	.ant-layout-sider.sider-primary
	.ant-menu-inline
	.ant-menu-item.menu-item-header {
	padding: 10px 16px !important;
	color: #8c8c8c;
	font-weight: 700;
	font-size: 13px;
	text-transform: uppercase;
	display: block;
}

.menu-item-header:hover {
	color: inherit;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-zero-width {
	overflow-x: hidden;
	width: 210px;
	margin: 0;
	padding: 33px 0;
}

.layout-dashboard.has-sidebar .ant-layout-sider.sider-primary .aside-footer {
	display: block;
}

.layout-dashboard
	.ant-layout-sider.sider-primary.ant-layout-sider-primary
	.footer-box {
	background-color: #1890ff;
	color: #fff;
	box-shadow: none;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box {
	background-color: #fff;
	color: #141414;
	padding: 16px;
	border-radius: 8px;

	margin-bottom: 8px;
}

.layout-dashboard
	.ant-layout-sider.sider-primary.ant-layout-sider-primary
	.footer-box
	.icon {
	background-color: #fff;
}

/* .sidenav_footer {
    display: inline;
} */

.layout-dashboard .ant-layout-sider.sider-primary .footer-box .icon {
	display: inline-flex;
	width: 32px;
	height: 32px;

	border-radius: 6px;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
	background-color: #1890ff;
}

.layout-dashboard
	.ant-layout-sider.sider-primary.ant-layout-sider-primary
	.footer-box
	h6 {
	color: #fff;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box h6 {
	font-weight: 600;
	font-size: 16px;
	color: #141414;
	margin-bottom: 0;
}

.layout-dashboard
	.ant-layout-sider.sider-primary.ant-layout-sider-primary
	.footer-box
	p {
	color: #f5f5f5;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box p {
	color: #8c8c8c;
	font-weight: 600;
}

.layout-dashboard
	.ant-layout-sider.sider-primary.ant-layout-sider-primary
	.footer-box
	button {
	background-color: #fff;
	border-color: #f0f0f0;
	color: #141414;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box button {
	margin: 0;
}

.ant-btn-sm,
.ant-radio-group-small .ant-radio-button-wrapper {
	height: 34px;
	font-size: 12px;
	padding: 0 15px;
}

.ant-btn-sm {
	border-radius: 4px;
}

.ant-btn-sm,
.ant-radio-group-small .ant-radio-button-wrapper {
	height: 34px;
	font-size: 12px;
	padding: 0 15px;
}

.ant-btn-sm {
	border-radius: 4px;
}

.ant-btn,
.ant-radio-group .ant-radio-button-wrapper {
	font-weight: 600;
}

.layout-dashboard .ant-layout-sider.sider-primary .aside-footer {
	padding-top: 100px;
	padding-bottom: 33px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
	display: block;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
	overflow-x: hidden;
	/* left: 32px; */
}

.layout-dashboard .ant-layout {
	background: transparent;
	position: relative;
	min-height: 100vh;
	width: 100%;
	flex-shrink: 0;
}

.layout-dashboard .ant-layout-header {
	background: transparent;
	height: auto;
	padding: 16px;
	margin: 10px 4px;
	line-height: inherit;
	border-radius: 12px;
	transition: 0.2s;
}

.layout-dashboard .ant-breadcrumb > span .ant-breadcrumb-link a {
	color: #8c8c8c;
}

.layout-dashboard .ant-breadcrumb > span:last-child .ant-breadcrumb-link {
	color: #141414;
}

.layout-dashboard .ant-page-header-heading {
	margin-top: 0;
	width: fit-content;
	.anticon-arrow-left {
		margin: auto;
		margin: 1rem 1rem 0 0;
		cursor: pointer;
		stroke:#000;
		stroke-width: 40;
	}

}

.layout-dashboard .ant-page-header-heading .ant-page-header-heading-title {
	font-family: Manrope;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 36px;
	letter-spacing: 0.01em;
	margin-top: 5px;
}

.notice-header {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6b7280;
}

.btn_change {
	height: 38px;
	width: 79px;

	top: 5px;
	border-radius: 6px;
	padding: 9px, 13px, 9px, 13px;
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
	margin: 0 16px;
	vertical-align: middle;
	background: none !important;
	color: black !important;
}

.mkt_header_heading {
	margin-top: 0;
	display: flex;
	flex-direction: row;
	justify-content: right;
	align-items: flex-end;
	padding: 8px 16px;

	width: 100%;
	height: 54px;

	border-radius: 5px;
}

.mkt_header_heading button {
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.01em;
	justify-items: center;
	text-align: center;
	height: 38px;
	width: 108px;
	top: 0px;
	border-radius: 6px;
}

.mkt_header_search {
	display: flex;
	flex-direction: column;
	gap:0.5rem;
	width: 100%;
	padding: 0.5rem 0;
}

.search_title {
	font-size: 2rem;
	font-weight: 500;
	color: black;
}

.ant-select{
	width:100%;
}

.mkt_header_select {
	margin-top: 0;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: flex-end;
	padding: 8px 0px;
	float: left;
	width: 100%;
	height: 54px;
	.ant-select-selection-placeholder{
		font-size: 14px;
	}
}

.mktheader {
	background: #f9fafb;
	margin-left: 0.2% !important;
	margin-right: 0.2% !important;
	margin-bottom: 1rem;
	border-radius: 5px;
}

.card_mktplace {
	margin-top: 1rem;
}

.layout-dashboard .header-control {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}

.ant-dropdown {
	z-index: 100000;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown {
	background: #fff;

	border-radius: 12px;
	min-width: 276px;
	padding: 16px 8px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
	ul.ant-dropdown-menu-items {
	margin: 0px;
	padding: 0px;
}

// .ant-dropdown.ant-dropdown-placement-bottomRight {
// 	min-width: inherit !important;
// }

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
	.ant-list-item {
	padding: 5px 16px;
	margin-bottom: 8px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
	.ant-avatar {
	width: 36px;
	height: 36px;
	background-color: #fafafa;
	box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
	.ant-list-item-meta-title {
	line-height: normal;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdownt
	.ant-list-item-meta-description {
	color: #8c8c8c;
	font-size: 12px;
	line-height: 12px;
	font-weight: 600;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
	.ant-list-item-meta-description
	svg {
	width: 14px;
	height: 14px;
	fill: #8c8c8c;
	vertical-align: middle;
	margin-right: 4px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
	.ant-list-item-meta-description
	svg
	path {
	fill: #8c8c8c;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
	.ant-list-item-meta-description
	span {
	vertical-align: middle;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
	.ant-avatar
	.ant-avatar-string {
	top: 6px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
	.ant-avatar
	.ant-avatar-string
	svg {
	width: 22px;
	height: 22px;
}

.h3,
.h4,
h3,
h4 {
	font-weight: 700;
	color: #141414;
}

.ant-list-item-meta-title > a {
	color: rgba(0, 0, 0, 0.65);
	transition: all 0.3s;
}

.ant-list-item-meta-title {
	margin-bottom: 4px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 22px;
}

.layout-dashboard .header-control .ant-btn-link {
	height: auto;
	padding: 0 7px;
	margin: 0;
	box-shadow: none;
	color: #8c8c8c;
	line-height: normal;
}

.ant-btn svg {
	margin-right: 5px;
}

.layout-dashboard .header-control svg {
	width: 20px;
	height: 20px;
}

.layout-dashboard .header-control svg path {
	fill: #141414;
}

.layout-dashboard .header-control svg {
	vertical-align: middle;
}

.layout-dashboard .header-control .header-search {
	width: 213px;
	margin: 0 7px 0 0;
	border-radius: 6px;
	height: 38px;
	border: 1px solid #d9d9d9;
}

.search_btn {
	height: 38px;
	width: 251px;

	border-radius: 6px;
}

.layout-dashboard .header-control .anticon.anticon-search svg {
	width: 16px;
	height: 16px;
}

.layout-dashboard .header-control .header-search svg path {
	fill: #8c8c8c;
}

p {
	font-size: 14px;
}

.h6,
h6 {
	font-size: 16px;
}

.layout-dashboard
	.header-control
	.header-search
	.ant-input-suffix
	.ant-input-search-icon.anticon-loading,
.layout-dashboard .header-control .sidebar-toggler {
	display: block;
}

.site-layout-background {
	background: transparent;
}

header.ant-layout-header.subheader {
	background: transparent;
}

.sidemenu {
	margin-top: 20px;
	margin-left: 20px;
}

.logo img {
	width: 32px;
	margin-right: 10px;
}

.logo {
	margin-bottom: 15px;
}

.logo span {
	font-size: 16px;
	font-weight: 600;
}

hr.horizontal.dark {
	background-image: linear-gradient(
		90deg,
		transparent,
		rgb(0 0 0 / 12%),
		transparent
	);
	border: 0px;
}

hr.horizontal {
	background-color: transparent;
}

hr:not([size]) {
	height: 1px;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav {
	background: transparent;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li {
	padding: 10px 16px !important;
	height: auto;
	line-height: normal;
	margin: 0px;
	border: 0px;
	font-size: 16px;
	color: #141414;
}


	li.ant-menu-item-selected {
	box-shadow: inset 0px 20px 27px #0000000d;
	border-radius: 8px;
	background: #fff;
	border: 0px;
	font-weight: 600;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
	li.ant-menu-item-selected {
	box-shadow: 0px 20px 27px #0000000d;
	border-radius: 8px;
	background: #fff;
	border: 0px;
	font-weight: 600;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
	.ant-menu-submenu-title
	> .ant-menu-title-content {
	flex: auto;
	min-width: 0;
	overflow: visible;
	text-overflow: ellipsis;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li::after {
	display: none;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
	li
	span.anticon {
	width: 32px;
	height: 32px;
	line-height: 32px;
	background: #fff;
	box-shadow: 0px 4px 6px #0000001f;
	border-radius: 6px;
	font-size: 16px;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
	li.ant-menu-item-selected
	span.anticon {
	background: #1890ff;
	color: #fff;
}

.divder {
	display: table;
	width: 100%;
	padding: 10px 15px;
	color: #8c8c8c;
	font-weight: 600;
	margin: 15px 0px;
}

.ant-card.ant-card-bordered.documentation-card {
	border: 0px;

	border-radius: 8px;
	background: #fff;
}

.p-15 {
	padding: 15px;
}

.card-document {
	width: 32px;
	height: 32px;
	text-align: center;

	border-radius: 6px;
	font-size: 19px;
	background: #1890ff;
	color: #fff;
	direction: ltr;
	margin-bottom: 15px;
}

.documentation-card h4 {
	margin: 0px;
}

.left-nav {
	margin-top: 15px;
}

.ant-layout {
	background: #fafafa;
}

.pageheader {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
	border: 0px;
}

.header-top h4 {
	margin: 0px;
	font-size: 20px;
}

.header-top .subtitle {
	display: block;
	font-weight: normal;
	color: #8c8c8c;
	font-size: 14px;
}

.header-top {
	margin-top: -10px;
}

.header-top {
	border-bottom: 1px solid rgb(140 140 140 / 7%);
	padding-bottom: 15px;
}

.setting button.ant-btn {
	padding: 0px;
	line-height: normal;
	background: transparent;
	border: 0px;
	box-shadow: none;
}

button.ant-btn.bell {
	padding: 0px;
	border: 0px;
	background: transparent;
	box-shadow: none;
	position: relative;
}

button.ant-btn.bell strong {
	position: absolute;
	top: -1px;
	right: -5px;
	display: inline-block;
	width: 20px;
	height: 20px;
	background: #f5222d;
	border-radius: 100%;
	text-align: center;
	line-height: normal;
	font-weight: normal;
	color: #fff;
}

.ant-card.criclebox {
	border-radius: 12px;
	border: 1px solid #e5e7eb;
	box-sizing: border-box;
}

.ant-card.mktbox {
	height: 328px;
	border-radius: 12px;
	border: 1px solid #e5e7eb;
	box-sizing: border-box;
}

.marketplace_tab_header {
	background: #f9fafb;
	border-radius: 8px !important;
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
}
@media screen and (max-width: 800px) {
	.marketplace_tab_header {
	  display: flex;
	  flex-direction: column;
	}
  }

.deals_table .ant-table-thead {
	background-color: white !important;
}

.deals_table .ant-table-filter-trigger {
	display: none !important;
}

// .deals_table .ant-dropdown-trigger {
// 	display: none !important;
// }

.deals_table .ant-table-column-sorter-full {
	display: none !important;
}

.number span {
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
}

.number h3 {
	font-family: Manrope;
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: 38px;
	letter-spacing: 0.01em;
	text-align: left;
}

.number h3 small {
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.mkttag {
	padding: 5px 0px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	float: left;
}

.mkttag .eco {
	/* border-radius: 16px; */
	border: none;
	background: #f9f5ff;
	color: #6941c6;
}

.mkttag .nature {
	border: none;
	background: #eff8ff;
	color: #175cd3;
}

.location_filter {
	display: flex;
	flex-direction: row;
	justify-items: center;
	align-items: center;
	height: 42px;
	width: 264px;
	left: 0px;
	top: 0px;
	border-radius: 8px !important;
	padding: 9px, 14px, 9px, 14px;
}
.ant-picker-input > input::placeholder {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	/* Text: Secondary */
	color: #6b7280;
}
.mkttag .nature2 {
	border-radius: 16px;
	background: #ecfdf3;
	color: #027a48;
}

.mkttag .flowers {
	border: none;
	background: #f0f9ff;
	color: #1e40af;
}

.mkttag .management {
	border-radius: 16px;
	background: #fdf2fa;

	color: #c11574;
}

.mkttag .marketing {
	border-radius: 16px;
	background: #eef4ff;
	color: #3538cd;
}

.mkttag .product {
	border-radius: 16px;
	background: #f8f9fc;
	color: #363f72;
}

.mktcard h3 {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	margin: 0;
}

.setting_card h2 {
	font-family: Manrope;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.01em;
	text-align: left;
}

.setting_card h3 {
	font-family: Manrope;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.01em;
	text-align: left;
}

.setting_card h5 {
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.setting_card p {
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.pay-method {
	text-align: center;
}

.setting_card button {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.01em;
	height: 38px;
	width: auto;
	color: #ffffff;
	top: 1px;
	padding: 9px, 17px, 9px, 17px;
	background: #0b5394;
	border-radius: 6px;
}

.setting_card label {
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.setting_card input {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 400;	
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.01em;
	color: #111827;
}

.mktcard h3 small {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;

	letter-spacing: 0.01em;
}

.icon-box {
	width: 48px;
	height: 48px;
	text-align: center;
	background: #1890ff;
	color: #fff;
	border-radius: 0.5rem;
	margin-left: auto;
	line-height: 55px;
}

.bnb2 {
	color: #12b76a;
}

.last_month {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 500;
	margin-left: 10px !important;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* Gray/500 */
	color: #667085;
}

.pie_chart_title {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
	/* identical to box height, or 156% */
	letter-spacing: 0.01em;
	/* Text */
	color: #111827;
	text-align: center;
}

.icon-box span {
	color: #fff;
	font-size: 24px;
}

.listpage {
	padding-top: 15px;
}

.bar-chart {
	background: transparent;

	border-radius: 8px !important;
}

.chart-vistior {
	margin-top: 15px;
}

.bg-selected {
	background-color: #F9FAFB !important;
}

.tag_text {
	color: inherit;
	border-radius: 10px;
}

.chart-vistior h5 {
	font-family: Manrope;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 50px;
	letter-spacing: 0.01em;
	text-align: justify;
	margin-left: 15px;
}

.chart-vistior h4 {
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: center;
}

.ant-typography.lastweek {
	color: #8c8c8c;
	font-weight: 600;
}

.chart-visitor-count h4 {
	margin: 0px;
	font-weight: 700;
}

.chart-visitor-count span {
	color: rgba(0, 0, 0, 0.65);
	font-weight: 600;
}

.linechart h5 {
	font-weight: 700;
	margin: 0px;
}

.linechart {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.project-ant h4 {
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0em;
	text-align: left;
}

.project-ant p {
	font-family: inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0em;
	text-align: left;
}

span.blue {
	margin-left: 5px;
	color: #1890ff;
	font-weight: 600;
}

.project-ant h5 {
	margin: 0px;
	color: #141414;
	font-weight: 700;
}

span.blue {
	margin-left: 5px;
	color: #1890ff;
	font-weight: 600;
}

.project-ant {
	border-bottom: 1px solid #f0f0f0 !important;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 68px;
}

.ant-list-box th {
	text-align: left;
	border-bottom: 1px solid #0000000d;
	padding: 8px 0px;
	color: #8c8c8c;
	font-size: 12px;
	font-weight: 600;
}

.ant-list-box td {
	text-align: left;
	border-bottom: 1px solid #0000000d;
	padding: 16px 25px;
	white-space: nowrap;
	font-size: 12px;
	font-weight: 600;
}

.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	.ant-table-row {
		cursor: pointer;
	}
}
.table-responsive-ext {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

.edit-menu__item{
	display: flex;
	align-items: center;
	justify-content: center;
}
.menu-item-icon{
	display: flex !important;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
}

.ant-table-tbody > tr > td {
	border-bottom: none;
}

.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.d-flex {
	display: flex !important;
}

.justify-content-center {
	justify-content: center !important;
}

.flex-column {
	flex-direction: column !important;
}

.me-3 {
	margin-right: 1rem !important;
}

.tootip-img {
	width: 24px;
	height: 24px;
	border-radius: 100%;
	border: 1px solid #fff;
	position: relative;
	z-index: 2;
	cursor: pointer;
}

.avatar-group .tootip-img + .tootip-img {
	margin-left: -10px;
}

.tootip-img:hover {
	z-index: 3;
	cursor: pointer;
}

.title-box h5 {
	margin: 0px;
}

.text-xs {
	color: #8c8c8c;
}

.card_detail_head {
	margin: 0;
	padding: 0px;
}
.percent-progress span.ant-progress-text {
	display: flex;
	margin-top: -27px;
	color: #8c8c8c;
	position: relative;
	margin-right: 0px;
}

.percent-progress .ant-progress-status-exception .ant-progress-text {
	color: #ff4d4f;
}

.ant-full-box {
	width: 100%;
	border: 1px dashed #d9d9d9;
	padding: 10px;
	height: auto;
	color: #141414;
	border-radius: 10px;
}

.ant-list-box h5 {
	font-size: 14px;
}

img {
	max-width: 100%;
}

.border10 {
	border-radius: 10px;
}

.py-4 {
	padding: 20px;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

span.ismobile {
	display: none;
	margin-right: 15px;
	cursor: pointer;
}

@media (max-width: 992px) {
	.footer-menu ul {
		justify-content: center;
	}

	footer.ant-layout-footer {
		padding: 0px 15px;
		text-align: center;
	}

	.project-ant {
		display: block;
	}

	.ant-filtertabs {
		text-align: right;
	}

	header.ant-layout-header.subheader {
		padding: 0px 24px 0px 0px;
	}

	main.ant-layout-content.content-ant {
		padding-left: 0px !important;
	}

	.ant-row {
		margin-left: -12px;
		margin-right: -12px;
	}

	span.ismobile {
		display: inline-block;
	}

	.bar-chart {
		width: 100% !important;
		max-width: 100%;
	}

	.full-width {
		max-width: 100% !important;
	}

	.bar-chart {
		width: 100% !important;
	}

	.icon-move-right {
		margin-bottom: 15px;
	}

	.pageheader {
		display: block;
	}

	.ant-cret img {
		width: 100%;
		height: 300px;
		object-fit: cover;
	}
}

.sidebar-color {
	padding: 1.5rem 0px;
}

.theme-color button span {
	opacity: 0;
}

.mb-2 {
	margin-bottom: 25px;
}

.sidebarnav-color button {
	width: 100%;
	margin-right: 10px;
	box-shadow: 0 3px 5px -1px rgb(0 0 0 / 9%), 0 2px 3px -1px rgb(0 0 0 / 7%);
	height: 40px;
}

.sidebarnav-color h5 {
	margin: 0px;
}

.sidebarnav-color span.ant-typography {
	color: #8c8c8c;
}

.sidebarnav-color .trans {
	margin-top: 1rem;
	display: flex;
}

.sidebarnav-color button:last-child {
	margin-right: 0px;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li:active {
	background: transparent;
}

.aside-footer {
	padding-top: 100px;
}

.ant-card-body {
	padding: 16px;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li svg path {
	color: #bfbfbf;
}

.cardbody .ant-card-body {
	padding-left: 0px;
	padding-right: 0px;
}

.ant-card.criclebox .project-ant {
	padding-left: 24px;
	padding-right: 24px;
}

.ant-card.criclebox table th {
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 14px;
	padding-bottom: 14px;
}

.percent-progress .ant-progress-bg {
	height: 3px !important;
}

.setting_btn {
	float: right;
}
.dashboard_password_divider {
	background: #e5e7eb !important;
	/* width: 90% !important; */
}

.divider-length {
	width: 98% !important;
}
.password-guidelines {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-top: -21px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* gray/500 */
	color: #6b7280;
}
.password_title_label {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-top: 0px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* gray/500 */
	color: #6b7280;
}

.general_title {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin-top: 2rem;
	/* identical to box height, or 150% */
	letter-spacing: 0.01em;
	/* gray/700 */
	color: #374151;
}
.kyc_title {
	margin-top: 1rem;
}
.kyc_form {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	letter-spacing: 0.01em;
	/* gray/700 */
	color: #374151;
	margin-top: 1rem;
}
.kyc_divider {
	margin-top: 0px !important;
}
.personal_id_subtitle {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	margin-top: -10px;
	letter-spacing: 0.01em;
	/* gray/500 */
	color: #6b7280;
}
.payment_method_subtitle {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-top: -5px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* gray/500 */
	color: #6b7280;
}

.payment_title {
	margin-top: 1rem;
}
.payment_method_title {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* Text: Secondary */
	color: #6b7280;
}

.bank_account {
	text-align: center;
	font-family: "Manrope";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */
	letter-spacing: 0.01em;
	/* gray/900 */
	color: #111827;
}
.modal_bank_name {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* gray/700 */
	color: #374151;
	margin-top: 4rem;
}
.bank_account_input_title {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* gray/700 */
	color: #374151;
}
.bank_account_input_field {
	margin-top: -1rem !important;
}
.bank_account_input_field_name {
	margin-top: -0.1rem !important;
}

.bank-modal-header .ant-btn-primary {
	background: #0b5394 !important;
}

.bank-modal-header .ant-modal-footer {
	margin-top: -3rem !important;
}
.kyc_subtitle {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	margin-top: -5px;
	letter-spacing: 0.01em;

	/* gray/500 */

	color: #6b7280;
}

.checkbox_customized_options .ant-checkbox-checked {
	background: #0b5394 !important;
	width: 16px;
	height: 16px;
	border: 1px solid #0b5394 !important;
	box-sizing: border-box;
	border-radius: 4px;
}

.checkbox_customized_options .ant-checkbox-inner {
	background: transparent !important;
	height: 16px;
	width: 16px;
	/* width: 16px;
	height: 16px;
	border: 1px solid #0b5394 !important;
	box-sizing: border-box; */
	border-radius: 4px;
}
.notification_title {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */
	letter-spacing: 0.01em;
	/* gray/900 */
	color: #111827;
}

.notification_subtitle {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-top: -10px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* gray/500 */
	color: #6b7280;
}

.btn_bankaccount {
	min-height: 50vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.ant-modal{
	z-index: 1000000;
}

.ant-modal-content {
	border-radius: 8px !important;
}
.cl_btn {
	height: 38px !important;
	width: 126px;

	top: 0px;
	border-radius: 6px !important;
	padding: 9px, 16px, 9px, 16px;
	font-family: Manrope !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: center !important;
}

.btn-common {
	top: 0px;
	border-radius: 6px !important;
	// padding: 9px 16px 9px 16px !important;
	background: #0b5394 !important;
	color: white !important;
	font-family: Manrope !important;
	font-size: 14px !important;
	font-style: normal !important	;
	font-weight: 600 ;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: center !important;
}

.save_btn {
	height: 38px !important;
	width: 120px;

	top: 0px;
	padding: 9px, 16px, 9px, 16px;
	background: #0b5394 !important;
	border-radius: 6px !important;
	color: white !important;
	font-family: Manrope !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: center !important;
}

.ant-card.tablespace .ant-card-body {
	padding: 0px;
}

.tablespace .ant-card-head-title {
	font-size: 20px;
	font-weight: 600;
}

.ant-radio-button-wrapper:first-child {
	border-radius: 4px 0 0 4px;
}

.ant-radio-button-wrapper:last-child {
	border-radius: 0 4px 4px 0;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
	li.ant-menu-item-selected
	path {
	color: #fff;
}

.ant-avatar-group .ant-avatar-square {
	border-radius: 4px;
}

.shape-avatar {
	margin-right: 20px;
}

.shape-avatar img {
	border-radius: 4px;
}

.avatar-info h5 {
	font-weight: bold;
	font-size: 14px;
	line-height: 14px;
	margin-bottom: 3px;
}

.avatar-info p {
	font-size: 14px;
	font-weight: 400;
	color: #8c8c8c;
	margin: 0px;
}

.ant-table-tbody > tr > td h5 {
	font-size: 14px;
	font-weight: 600;
	color: #141414;
	margin-bottom: 0px;
}

.ant-table-tbody > tr > td p {
	font-size: 14px;
	color: #8c8c8c;
	margin: 0px;
}

.tag-primary {
	border-radius: 6px;
	padding: 2px 12px;
	font-size: 14px;
	height: auto;
	line-height: 24px;
	font-weight: 600;
}

.ant-employed {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 500;
	color: #8c8c8c;
}

.ant-employed a {
	color: #8c8c8c;
	text-decoration: none;
	font-weight: 600;
}

.tag-badge {
	border-radius: 6px;
	padding: 2px 12px;
	font-size: 14px;
	height: auto;
	line-height: 24px;
	font-weight: 600;
	background: #8c8c8c;
	color: #fff;
	outline: none;
}

.tag-badge:hover {
	background: #8c8c8c;
	color: #fff;
	border-color: #8c8c8c;
	opacity: 0.9;
}

.ant-avatar-group {
	align-items: center;
}

.mb-24 {
	margin-bottom: 24px;
}

.ant-table-thead > tr > th {
	color: #8c8c8c;
	font-weight: 700;
	background-color: transparent;
	font-size: 12px;
}

.ant-border-space th:before {
	display: none;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
	padding: 16px 25px;
}

.tablespace {
	overflow: hidden;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
	padding: 16px 25px;
}

.avatar-sm {
	width: 25px;
}

.ant-progress-project {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ant-progress-project .ant-progress-outer {
	float: right;
}

.ant-progress-project .ant-progress-text {
	margin: 0px 0px 0px 0px;
	color: #8c8c8c;
	font-weight: 600;
}

.ant-progress-project .ant-progress {
	margin-right: 15px;
}

.ant-progress-project .ant-progress-bg {
	height: 3px !important;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
	white-space: nowrap;
}

.h-full {
	height: 100%;
}

@media (min-width: 1200px) {
	.ant-col.col-img img {
		height: 196px;
	}
}

@media (max-width: 1024px) {
	.bar-chart {
		max-width: 100%;
	}

	.full-width {
		max-width: 100%;
	}
}

.card-info-2 .ant-card-body {
	height: 100%;
}

.card-content p {
	font-size: 16px;
}

.ant-layout-footer {
	padding: 0px 50px;
}

.layout-default .ant-layout-header {
	display: flex;
}

.header-col.header-nav {
	margin-left: auto;
	width: 100%;
	margin-right: auto;
}

.header-col.header-nav .ant-menu-overflow {
	justify-content: center;
}

.header-col.header-nav .ant-menu-horizontal {
	background: transparent;
	box-shadow: none;
	border: 0px;
}

.header-col.header-nav li:after {
	display: none;
}

.header-col.header-brand {
	white-space: nowrap;
}

.header-col.header-brand h5 {
	margin: 0px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
}

.header-col.header-nav {
	color: #fff;
	font-weight: 600;
}

.header-col.header-nav ul li {
	color: #fff !important;
}

.header-col.header-nav ul li img {
	margin-top: -2px;
	margin-right: 5px;
}

.header-col.header-btn button {
	height: 34px;
	padding: 0 16px;
	border-radius: 34px;
	line-height: 33px;
}

.text-lg {
	font-size: 16px;
}

.header-solid .ant-card-head {
	border-bottom: 0;
}

.m-25,
.mb-25,
.my-25 {
	margin-bottom: 25px;
}

.m-25,
.mt-25,
.my-25 {
	margin-top: 25px;
}

.text-center {
	text-align: center;
}

.font-semibold {
	font-weight: 600;
}

.text-muted {
	color: #8c8c8c;
}

.ant-input {
	border-radius: 6px;
}

.ant-input {
	font-weight: 600;
	color: #8c8c8c;
}

.ant-input {
	height: auto;
	border: 1px solid #d9d9d9;
}

.font-bold {
	font-weight: 700;
}

.text-dark {
	color: #141414;
}

.ant-btn,
.ant-radio-group .ant-radio-button-wrapper {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;

	padding: 0 15px;
	line-height: 30px;
}

/* .ant-btn {
    box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
    border-radius: 6px;
} */
.rb_chart {
	float: right;
}

.layout-default .ant-layout-footer {
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	background: transparent;
	text-align: center;
}

.layout-default .ant-layout-footer .ant-menu-horizontal {
	border: none;
	line-height: 1.5;
	margin: 0 0 30px;
	background-color: transparent;
	font-size: 16px;
}

.layout-default .ant-layout-footer .ant-menu-horizontal {
	text-align: center;
	display: block;
}

.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item,
.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item-active,
.layout-default
	.ant-layout-footer
	.ant-menu-horizontal
	> .ant-menu-item-selected,
.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item:hover,
.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-submenu,
.layout-default
	.ant-layout-footer
	.ant-menu-horizontal
	> .ant-menu-submenu-active,
.layout-default
	.ant-layout-footer
	.ant-menu-horizontal
	> .ant-menu-submenu-selected,
.layout-default
	.ant-layout-footer
	.ant-menu-horizontal
	> .ant-menu-submenu:hover {
	color: #8c8c8c;
	border: none;
}

.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item-active,
.layout-default
	.ant-layout-footer
	.ant-menu-horizontal
	> .ant-menu-item-selected,
.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item:hover,
.layout-default
	.ant-layout-footer
	.ant-menu-horizontal
	> .ant-menu-submenu-active,
.layout-default
	.ant-layout-footer
	.ant-menu-horizontal
	> .ant-menu-submenu-selected,
.layout-default
	.ant-layout-footer
	.ant-menu-horizontal
	> .ant-menu-submenu:hover {
	color: #262626;
}

.layout-default .ant-layout-footer .menu-nav-social a svg {
	fill: #8c8c8c;
	vertical-align: middle;
}

.layout-default .ant-layout-footer .menu-nav-social a:hover svg {
	fill: #1890ff;
}

.layout-default .ant-layout-footer .copyright a {
	color: inherit;
	font-weight: normal;
}

.fill-muted {
	fill: #8c8c8c;
}

.layout-default .ant-layout-header .header-brand h5 {
	white-space: nowrap;

	color: #141414;
	margin: 0;
}

.layout-default .ant-layout-header .header-nav a svg {
	margin-right: 5px;
}

.layout-default .ant-layout-header .header-nav a span,
.layout-default .ant-layout-header .header-nav a svg {
	vertical-align: middle;
}

.layout-default .ant-layout-header .header-nav a {
	color: #141414;
	padding: 6px 12px;
	font-weight: 400;
}

.layout-default
	.ant-layout-header
	.header-nav
	.ant-menu-horizontal
	> .ant-menu-item,
.layout-default
	.ant-layout-header
	.header-nav
	.ant-menu-horizontal
	> .ant-menu-item-active,
.layout-default
	.ant-layout-header
	.header-nav
	.ant-menu-horizontal
	> .ant-menu-item-selected,
.layout-default
	.ant-layout-header
	.header-nav
	.ant-menu-horizontal
	> .ant-menu-item:hover,
.layout-default
	.ant-layout-header
	.header-nav
	.ant-menu-horizontal
	> .ant-menu-submenu,
.layout-default
	.ant-layout-header
	.header-nav
	.ant-menu-horizontal
	> .ant-menu-submenu-active,
.layout-default
	.ant-layout-header
	.header-nav
	.ant-menu-horizontal
	> .ant-menu-submenu-selected,
.layout-default
	.ant-layout-header
	.header-nav
	.ant-menu-horizontal
	> .ant-menu-submenu:hover {
	color: #8c8c8c;
	font-weight: 600;
	border: none;
}

.layout-default
	.ant-layout-header
	.header-nav
	.ant-menu-horizontal
	> .ant-menu-item {
	padding: 0;
}

.layout-default .ant-layout-content {
	padding-top: 40px;
}

.font-regular {
	font-weight: 400;
}

.ant-row.ant-form-item.username {
	display: block;
}

.ant-row.ant-form-item.username
	.ant-col.ant-form-item-label
	.ant-form-item-required:before {
	display: none;
}

.ant-row.ant-form-item.username .ant-col.ant-form-item-label {
	font-size: 30px;
	font-weight: 600;
	color: #141414;
}

.text-dark {
	color: #141414;
}

.layout-default .ant-layout-footer .copyright {
	font-size: 16px;
	color: #8c8c8c;
}

.row-col .ant-row {
	margin-left: 0px;
	margin-right: 0px;
}

button.ant-btn.bell strong {
	position: absolute;
	top: -1px;
	right: -9px;
	display: inline-block;
	width: 18px;
	height: 18px;
	background: #f5222d;
	border-radius: 100%;
	text-align: center;
	line-height: normal;
	font-weight: normal;
	color: #fff;
}

.layout-dashboard .ant-layout-footer {
	background: transparent;
	margin: 0 20px 20px 20px;
	padding: 0;
}

.layout-dashboard .ant-layout-content {
	padding: 0;
	margin: 0 20px 0;
}

.ant-layout-content.chat-content {
	margin: 0 !important;
}

.m-10,
.mr-10,
.mx-10 {
	margin-right: 10px !important;
}

.ant-table-tbody > tr > td h6 {
	font-size: 14px;
	font-weight: 600;
	color: #141414;
}

.layout-dashboard .header-control .header-search input.ant-input {
	height: auto;
}

small.redtext {
	color: red;
}

.full-width path {
	fill: transparent;
}

.bar-chart line.apexcharts-xaxis-tick {
	stroke: transparent;
}

section.ant-layout.layout-content .ant-layout-content.content-ant {
	padding: 0px !important;
}

.layout-dashboard .ant-card {
	border-radius: 8px;
}

.card-credit {
	background-color: #fff;

	background-position: 50%;
	background-size: cover;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	/* border: 1px solid #ccc; */
}

.card_tag {
	color: #000;
	border-radius: 16px;
}

.card-credit * {
	color: #000;
	border-radius: 16px;
}

.img_position {
	height: 100px;
	flex: 2;
	width: "100%";
	position: relative;
	object-fit: cover;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.side-menu-item {
	margin-bottom: 8px !important;
}

.bell_badge .ant-badge-count {
	background: #0b5394 !important;
	margin-top: 16px !important;
}
.password_field-dashboard {
	border-radius: 6px !important;
}

.deals_profile_avatar {
	width: 54px !important;
	height: 54px !important;
}
.card-credit .card-number {
	font-family: Manrope;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0.01em;
	text-align: left;
}

.mkt_detail {
	float: left;
	padding-top: 25px;
}

.avatar_position {
	background-image: url(../images/user.png);
	position: absolute;
	top: 27%;
}
.avatar_position_marketplace {
	position: absolute;
	top: 20%;
}

.ant-card-head-wrapper {
	min-height: 72px;
}

.card-credit .ant-card-body {
	padding-top: 15px;
}

.card-credit .card-footer {
	display: flex;
	align-items: flex-end;
}

.m-auto,
.ml-auto {
	margin-left: auto !important;
}

.m-30,
.mr-30,
.mx-30 {
	margin-right: 30px !important;
}

.card-footer-amount {
	margin: 0;
}

.card-credit .card-footer h6,
.card-credit .card-footer p {
	margin-bottom: 0;
}

.card-credit .col-logo img {
	max-width: 50px;
}

.card-credit .card-footer h6 {
	font-weight: 700;
}

.width-100 {
	width: 100%;
}

.p-20 {
	padding: "0px 20px 0px 0px";
}

.layout-default .ant-layout-content.p-0 {
	padding: 0px;
}

.pt {
	padding-top: 0px;
}

.widget-2 .icon {
	width: 64px;
	height: 64px;
	margin: auto;
	background-color: #1890ff;
	border-radius: 8px;
	box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
}

.widget-2 .ant-statistic-title h6 {
	font-weight: 600;
}

.widget-2 .ant-statistic-title p {
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 0;
}

.widget-2 .ant-statistic-content {
	font-weight: 600;
	font-size: 20px;
	padding: 12px 16px;
	border-top: 1px solid #f5f5f5;
	text-align: center;
}

.widget-2 .ant-statistic-title {
	text-align: center;
	padding: 16px;
}

.widget-2 .ant-card-body {
	padding: 0;
}

.widget-2 .icon img {
	width: 30px;
}

.ant-row-flex-middle {
	align-items: center;
}

.d-flex {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.ant-btn.ant-btn-link.ant-edit-link {
	font-weight: 600;
	font-size: 12px;
	height: 40px;
	padding: 0 15px;
	box-shadow: none;
}

.ant-btn.ant-btn-link.ant-edit-link img {
	width: 20px;
}

.fill-gray-7 {
	fill: #8c8c8c !important;
}

.m-0 {
	margin: 0px;
}

.header-solid .ant-card-head {
	border-bottom: 0;
}

.ant-btn > svg,
.ant-btn > svg + span {
	vertical-align: middle;
}

.ant-descriptions .ant-descriptions-title {
	font-size: 14px;
	font-weight: 600;
	color: #141414;
	margin-bottom: 14px;
}

.fill-danger {
	fill: #ff4d4f;
}

.col-action {
	display: flex;
}

.card-billing-info .col-action button {
	height: 34px;
	font-size: 12px;
	padding: 0 15px;
	box-shadow: none;
}

.ant-descriptions .ant-descriptions-row > td,
.ant-descriptions .ant-descriptions-row > th {
	padding-bottom: 6px;
}

.ant-descriptions .ant-descriptions-item-label {
	font-size: 30px;
	font-weight: 600;
	color: #8c8c8c;
}

.ant-descriptions .ant-descriptions-item-content {
	font-size: 13px;
	font-weight: 600;
	color: #141414;
}

.ant-descriptions .ant-descriptions-title {
	font-size: 14px;
	font-weight: 600;
	color: #141414;
	margin-bottom: 14px;
}

.card-header-date {
	margin-bottom: 0;
	font-weight: 700;
	color: #8c8c8c;
}

.card-header-date > * {
	vertical-align: middle;
}

.card-header-date svg {
	margin-right: 5px;
}

.transactions-list .amount {
	font-size: 16px;
	font-weight: 700;
}

.text-light-danger {
	background: #fde3cf;
	color: #f56a00;
}

.text-fill {
	color: #52c41a;
	background: #edf9e7;
}

.text-danger {
	color: #f5222d;
}

.text-success {
	color: #52c41a;
}

.transactions-list .ant-list-item-meta {
	align-items: center;
}

.text-warning {
	background: rgb(255, 252, 231);
	color: #fadb14;
}

.text-warning-b {
	font-size: 14px;
	font-weight: 600;
	color: #fadb14;
}

.layout-dashboard .ant-affix .ant-layout-header {
	background: #fff;
	box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
	margin: 10px 20px;
	z-index: 10;
}

.ant-row.ant-form-item.aligin-center .ant-form-item-control-input-content {
	display: flex;
	align-items: center;
	color: rgba(0, 0, 0, 0.65);
}

.profile-nav-bg {
	height: 300px;
	margin-top: -137.8px;
	border-radius: 12px;
	box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
	background-size: cover;
	background-position: 50%;
}

.card-profile-head .ant-card-head {
	border-bottom: 0px;
	padding: 0px 16px;
}

.card-profile-head {
	margin: -53px 0 24px;
	border: none !important;
}

.card-profile-head .ant-card-head .ant-avatar {
	box-shadow: 0 5px 10px rgb(0 0 0 / 12%);
	border-radius: 8px;
	margin: 0;
}

.card-profile-head .ant-card-head .avatar-info {
	margin-left: 16px;
}

.ant-card-head-title p {
	font-weight: 600;
	color: #8c8c8c;
	margin-bottom: 0;
}

.card-profile-head .ant-card-head .avatar-info h4 {
	font-size: 24px;
}

.card-profile-head .ant-card-head .avatar-info h4 {
	font-size: 24px;
}

.card-profile-head .ant-radio-group .ant-radio-button-wrapper {
	height: auto;
	font-weight: 700;
}

.settings-list {
	list-style: none;
	padding: 0;
}

.settings-list > li:first-child {
	padding-top: 0;
}

.settings-list > li {
	padding: 12px 0;
}

.settings-list > li h6 {
	font-size: 12px;
	color: #8c8c8c;
	margin: 0;
	font-weight: 700;
}

.settings-list > li span {
	margin-left: 16px;
	color: #141414;
}

.ant-card.ant-list-yes .ant-list-header {
	border: 0px;
}

.ant-card.ant-list-yes .ant-list-header h6 {
	font-size: 12px;
	font-weight: 600;
	color: #8c8c8c;
	margin: 6px 0;
}

.ant-list.ant-list-split.transactions-list.ant-newest .ant-list-header {
	padding-top: 0px;
}

.card-profile-information hr {
	opacity: 0.2;
}

.card-profile-information .ant-descriptions .ant-descriptions-row > td,
.card-profile-information .ant-descriptions .ant-descriptions-row > th {
	padding-bottom: 16px;
}

.conversations-list .ant-list-item-meta-description {
	font-size: 12px;
	font-weight: 600;
	color: #8c8c8c;
}

.conversations-list .ant-avatar {
	border-radius: 10.5px;
	box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
}

.conversations-list .ant-list-item {
	padding: 14px 0;
}

.card-project.ant-card {
	box-shadow: none;
	border: none;
}

.card-project.ant-card .ant-card-cover img {
	border-radius: 8px;
	box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
}

.card-project.ant-card .card-tag,
.card-project.ant-card p {
	font-weight: 600;
	color: #8c8c8c;
	margin-bottom: 5px;
}

.card-project.ant-card h5 {
	font-weight: 600;
	font-size: 20px;
}

.card-project.ant-card .card-tag,
.card-project.ant-card p {
	font-weight: 600;
	color: #8c8c8c;
	margin-bottom: 5px;
}

.card-project.ant-card p {
	margin-bottom: 0;
}

.card-project.ant-card .ant-card-body {
	padding: 16px 0 0;
}

.card-project.ant-card .card-footer {
	margin-top: 22px;
}

.card-project.ant-card .card-tag,
.card-project.ant-card p {
	font-weight: 600;
	color: #8c8c8c;
	margin-bottom: 5px;
}

.card-credit .card-footer {
	display: flex;
	align-items: flex-end;
}

.card-credit .card-footer h6,
.card-credit .card-footer p {
	margin-bottom: 0;
}

.card-credit .card-footer h6 {
	font-weight: 700;
}

.card-project.ant-card .card-footer {
	margin-top: 22px;
}

.projects-uploader {
	height: 100%;
	border-radius: 8px;
	border-color: #d9d9d9;
}

.projects-uploader .ant-upload {
	width: 100%;
	height: 100%;
	margin: 0;
	border-radius: 8px;
	padding: 8px;
}

.projects-uploader .ant-upload-list.ant-upload-list-picture-card {
	height: 100%;
}

.projects-uploader .ant-upload.ant-upload-select-picture-card {
	background-color: transparent;
}

.projects-uploader svg {
	width: 20px;
	height: 20px;
}

.ant-descriptions .ant-descriptions-title {
	font-size: 14px;
	font-weight: 600;
	color: #141414;
	margin-bottom: 14px;
}

.ant-descriptions .ant-descriptions-row > td,
.ant-descriptions .ant-descriptions-row > th {
	padding-bottom: 6px;
}

.ant-descriptions .ant-descriptions-item-label {
	font-size: 13px;
	font-weight: 600;
	color: #8c8c8c;
}

.ant-descriptions .ant-descriptions-item-content {
	font-size: 13px;
	font-weight: 600;
	color: #141414;
}

.ant-descriptions .ant-descriptions-title {
	font-size: 14px;
	font-weight: 600;
	color: #141414;
	margin-bottom: 14px;
}

.card-billing-info.ant-card .ant-descriptions-header {
	margin-bottom: 0px;
}

.card-profile-information .ant-descriptions .ant-descriptions-row > td,
.card-profile-information .ant-descriptions .ant-descriptions-row > th {
	padding-bottom: 16px;
}

.invest_btn {
	height: 38px;
	width: 114px;
	top: 0px;
	border-radius: 6px;
	padding: 9px, 17px, 9px, 17px;
	background: #0b5394;
	color: white;
	border-radius: 6px;
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: center;
}

.share_btn {
	height: 38px;
	width: 114px;
	top: 0px;
	border-radius: 6px;
	padding: 9px, 17px, 9px, 17px;

	color: black;
	border-radius: 6px;
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: center;
}
.share_btn_deals {
	color: #374151;
	/* width: 1139px;
	height: 2280px;
	left: 301px;
	top: 0px;
	background: #ffffff; */
}

.deal_title {
	font-family: Manrope;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 36px;
	letter-spacing: 0.01em;
	text-align: left;
}

.deal_title p {
	font-family: Manrope;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.01em;
	text-align: left;
}

.deal_list {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #e5e7eb;
	align-items: justify;
	height: 100px;
	border-radius: 0 !important;
}

.deal_cd {
	display: flex;
	flex-direction: row;
	border: none;
	margin: 0;
}

.tab_deal {
	float: left;
}

.card_video_head {
	display: flex;
	flex-direction: column;

	align-items: justify;

	/* margin: -53px 24px 24px; */
}

.overviewlist {
	background: #f9fafb;
	border-radius: 6px;
	margin-bottom: 15px;
}

.setting-drwer {
	background: #fff;
	border-radius: 50%;
	bottom: 42px;
	right: 30px;
	font-size: 1.25rem;
	z-index: 990;
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 16%);
	width: auto;
	height: auto;
	text-align: center;
	line-height: 60px;
	position: fixed;
	cursor: pointer;
}

.hide-block {
	display: none;
}

.btn_chatbot {
	font-size: 20px;
	font-family: Manrope;
	float: right;
	width: 100%;
	padding: 8px;
	/* background-color: rgb(183, 0, 255); */
}

/* .proceed_list {
    border: none !important;
} */
/*Sidebar Responsive */
.navbar-fixed-top {
	border: 0;
}

/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
	display: none;
}
.menu{
	width: 100%;
	height:calc(100% - 70px);
	.ant-menu {
	overflow-y: auto;
	overflow-x: hidden;
	height:100%;
	width: 100%;
	}
/* width */
.ant-menu::-webkit-scrollbar {
	width: 3px;
  }
  
  /* Track */
  .ant-menu::-webkit-scrollbar-track {
	background: #e7eef5;
  }
  
  /* Handle */
  .ant-menu::-webkit-scrollbar-thumb {
	background: #0b5394;
  }
  
  /* Handle on hover */
  .ant-menu::-webkit-scrollbar-thumb:hover {
	background: #0b5494a9;
  }
}


@media (min-width: 768px) {
	.sidebar {
		position: fixed;
		top: 51px;
		bottom: 0;
		left: 0;
		z-index: 1000;
		display: block;
		padding: 20px;
		/* Scrollable contents if viewport is shorter than content. */
		/* background-color: #f5f5f5; */
		border-right: 1px solid #eee;
		
	}
}

/* Sidebar navigation */
/* .nav-sidebar {
    margin-right: -21px;
    margin-bottom: 20px;
    margin-left: -20px;
} */

#user_sidebar_button {
	position: absolute;
	display: inline-block;
	bottom: 0;
	right: 5px;
	z-index: 99999;
	/* left: 10px; */
	background: white;
	width: 100%;
	margin-bottom: 10px;

	.ant-row {
		display: flex;
		align-items: center;
	}
}

#sidebar_content {
	position: relative;
	min-height: 100%;
	padding-bottom: 90px;
	.brand{
		img{
			height: 65px;
		}
	}
}

#sidebar {
	width: inherit;
	min-width: 300px;
	max-width: 300px;
	/* background-color: #f5f5f5; */
	float: left;
	height: 100%;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
}

.card_custom_head .ant-card-head {
	padding: 0 !important;
}
.financial_title {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	/* identical to box height, or 150% */
	letter-spacing: 0.01em;
	/* Text */
	color: #111827;
	text-transform: capitalize;
}
.financial_statics_title {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* gray/500 */
	color: #6b7280;
}
.stats_percentage {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 38px;
	/* identical to box height, or 127% */
	letter-spacing: 0.01em;
	/* Gray/900 */
	color: #101828;
}

.card_custom_head.ant-card-head-title {
	padding: 0 !important;
}
/*
   * off Canvas sidebar
   * --------------------------------------------------
   */
@media screen and (max-width: 768px) {
	.sidebar-offcanvas {
		position: absolute;
		top: 0;
	}
}

.card_overview {
	box-shadow: none !important;
}

.proceed_list {
	border-bottom: none !important;
	border: none !important;
}

.pro_list h2 {
	border-bottom: 0 none;
	border: 0 none !important;
	text-align: justify;

	margin: 0;
}

.list-loadmore-edit {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	/* Primary */
	color: #0b5394;
}

.investment_timeline {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
	padding-bottom: 1rem !important;
	/* identical to box height, or 156% */
	letter-spacing: 0.01em;
	margin-left: 1rem !important;
	/* Text */
	color: #111827;
}
.area_chart {
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 64.12%,
		#ffffff 100%
	);
}
.area_chart_title {
	margin-top: 10px;
	font-family: "Manrope";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	/* Text: Secondary */
	color: #6b7280;
}

/* Dashboard table custmize */

.ant-table-thead {
	height: 44px !important;
	/* Items: Selected */
	background: #f9fafb !important;
}

.username_sidenav {
	margin: 0;

	overflow-wrap: break-word;
    font-size: 14px;
    text-align: center;
}

.filter-button {
	margin-right: -1rem !important;
}

/* @media all and (max-width: 1280px) {
	.filter_modal {
		top: 23% !important;
		left: 25% !important;
		width: 312px !important;
		
	}
} */

.filter_modal .ant-modal-close-x {
	display: none !important;
}
.filter_modal .ant-modal-content {
	border-radius: 6px !important;
}

.filter_modal .ant-modal-header {
	border-radius: 6px !important;
}
.sorting_modal .ant-modal-close-x {
	display: none !important;
}

.sorting_modal_deals .ant-modal-close-x {
	display: none !important;
}

.modal-title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.list-checkbox {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between !important;
}
.ant-checkbox-inner::after {
	width: 7px !important;
	height: 14px;
}

.ant-progress-inner {
	background: rgba(11, 83, 148, 0.2);
}
.filter_title_text {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
	/* identical to box height, or 156% */
	letter-spacing: 0.01em;
	/* Text */
	color: #111827;
}

.filter_title_save_view {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* Primary */
	color: #0b5394;
}

.filter_title_save_view:hover {
	cursor: pointer;
}

.filter_modal .ant-modal-header {
	height: 58px !important;
	
}

.ant-modal-header {
    border-radius: inherit !important;
  }

.panel-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.filter_modal
	.ant-collapse-icon-position-right
	> .ant-collapse-item
	> .ant-collapse-header {
	font-family: "Manrope" !important;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 20px !important;
	letter-spacing: 0.01em !important;
	color: #374151 !important;
}

.panel-content {
	margin-top: -10px !important;
}

.panel_heading_border {
	border-top: 1px solid #e5e7eb !important;
}

.ant-modal-body {
	padding-left: 24px !important;
	padding-right: 24px !important;
	font-size: 14px;
	line-height: 1.5715;
	word-wrap: break-word;
	margin-top: -30px !important;
	margin-bottom: 30px !important;
}

@media all and (max-width: 1920px) {
	.filter_modal {
		top: 14% !important;
		margin-right: 10% !important;
		width: 312px !important;
	}

	.sorting_modal {
		top: 16% !important;
		margin-right: 2% !important;
		width: 246px !important;
	}

	.sorting_modal_deals {
		top: 22% !important;
		margin-right: 2% !important;
		width: 246px !important;
	}
	// .ant-picker-panel-container {
	// 	margin-left: 500px !important;
	// }
}

.sorting_modal .ant-modal-close-icon {
	display: none !important;
}

@media all and (max-width: 1280px) {
	.filter_modal {
		top: 5% !important;
		margin-right: 12% !important;
		width: 312px !important;
	}

	.sorting_modal {
		top: 0% !important;
		padding-top: 0px !important;
		margin-right: 1% !important;
		width: 246px !important;
	}

	.sorting_modal_deals {
		top: 0% !important;
		padding-top: 0px !important;
		margin-right: 1% !important;
		width: 246px !important;
	}
	.ant-picker-panel-container {
		margin-left: 800px !important;
	}
}

/* MacBook Pro 2016 Resolution height:1600 */
@media all and (max-width: 2560px) {
	.filter_modal {
		top: 9% !important;
		margin-right: 6% !important;
		width: 312px !important;
	}

	.sorting_modal {
		top: 11% !important;
		margin-right: 2% !important;
		width: 312px !important;
	}

	.sorting_modal_deals {
		top: 15% !important;
		margin-right: 2% !important;
		width: 312px !important;
	}
	// .ant-picker-panel-container {
	// 	margin-left: 830px !important;
	// }
}
@media all and (max-width: 1440px) {
	.ant-picker-panel-container {
		margin-left: 250px !important;
	}
}

@media all and (max-width: 1440px) {
	.ant-picker-panel-container {
		margin-left: 250px !important;
	}
}

@media all and (max-width: 1366px) {
	.filter_modal {
		top: 22% !important;
		margin-right: 11% !important;
		width: 312px !important;
	}

	.sorting_modal {
		top: 26% !important;
		padding-top: 0px !important;
		margin-right: 4% !important;
		width: 246px !important;
	}

	.sorting_modal_deals {
		top: 36% !important;
		padding-top: 0px !important;
		margin-right: 4.5% !important;
		width: 246px !important;
	}

	.ant-picker-panel-container {
		margin-left: 0px !important;
	}
}

.sorting_modal .ant-list-split .ant-list-item {
	border-bottom: none !important;
}

.list_checkbox .ant-checkbox-checked .ant-checkbox-inner:after {
	border-color: #0b5394 !important;
}

.list_checkbox .ant-checkbox-checked .ant-checkbox-inner {
	background-color: white !important;
	border-color: white !important;
}

.mkttag .ant-checkbox-checked .ant-checkbox-inner {
	background-color: transparent !important;
}
.mkttag .ant-checkbox-checked .ant-checkbox-inner:after {
	border-color: #0b5394 !important;
}
.mkttag .ant-checkbox-checked .ant-checkbox-inner {
	outline: 0.5px solid #0b5394 !important;
	border-radius: 3px !important;
}
.mkttag .ant-checkbox-checked .ant-checkbox-inner {
	border-color: transparent !important;
}

.list-item-selected {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #111827;
}

.list-item-normal {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #374151;
}

.price-tag {
	line-height: 0;
	margin-top: -5px !important;
	margin-bottom: 3px;
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* Text */
	color: #111827;
}
.market-place-progress-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.marketplace_description {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* or 143% */
	letter-spacing: 0.01em;
	/* Text: Secondary */
	color: #6b7280;
}
.progress_percentage {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* Inactive: Main */
	color: #9ca3af;
}
.mt-10 {
	margin-bottom: 10px !important;
}

.deal_financials h2 {
	font-family: Manrope;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0.01em;
	text-align: left;
}

.deal_financials h3 {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.stats .ant-statistic-content-value {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: center;
}

.stats small {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: center;
}
.stats .ant-tag {
	display: flex;
	flex-direction: row;
	justify-content: center;

	align-items: center;
	color: #027a48;
	background-color: #ecfdf3;
	border-radius: 16px;
	height: 24px;
	width: 61px;
	left: 0px;
	top: 0px;
	margin: 8px;
	padding: 0px;
	border: 1px solid #ecfdf3;

	align-items: center;
	color: #027a48;
	background-color: #ecfdf3;
	border-radius: 16px;
	height: 24px;
	width: 61px;
	left: 0px;
	top: 0px;
	margin: 8px;
	padding: 0px;
	border: 1px solid #ecfdf3;
}
.stats1 .ant-tag {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: #b42318;
	background-color: #fef3f2;
	border-radius: 16px;
	height: 24px;
	width: 61px;
	left: 0px;
	top: 0px;
	margin: 8px;
	padding: 0px;
	border: 1px solid #fef3f2;
}

.pastfundraises_tbl .ant-table-thead {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.pastfundraises_tbl .ant-table tbody {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}
.pastfundraises_list .ant-list-items {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.team_card {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
	height: 184px;
	width: auto;

	top: 0px;
	border-radius: 5px;
	padding: 16px, 24px, 16px, 24px;

	top: 0px;
	border-radius: 5px;
	padding: 16px, 24px, 16px, 24px;
}
.team_card h3 {
	margin: 0;
	padding: 0;
	font-family: Manrope;
	font-size: 16px;
	font-weight: 600;

	letter-spacing: 0.01em;
	text-align: center;

	letter-spacing: 0.01em;
	text-align: center;
}

.team_Card small {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;

	letter-spacing: 0em;
	text-align: left;
}

.documents_tab h3 {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}
.documents_tab h2 {
	font-family: Manrope;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0.01em;
	text-align: left;
	color: #111827;
}

.documents_tab small {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.conditions_tab h3 {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
	color: #6b7280;
}

.ant-tabs-content {
	padding-top: 38px;
}

.conditions_tab h2 {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
	color: #6b7280;
}

.conditions_tab small {
	font-family: Manrope;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0.01em;
	text-align: left;
}

.threedot {
	height: 18px;
	width: 4px;
	left: 10px;
	top: 3px;
	border-radius: 0px;
}
.comment_input::placeholder {
	padding-top: 10px !important;
}

.condition_card {
	height: 193px;
	width: 303px;
	background: #f9fafb;
	border-radius: 8px;
}

.condition_card .ant-avatar > img {
	height: 40px;
	width: 40px;
	left: 0px;

	margin: 0px;
	padding: 0;
}

.condition_card h3 {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.condition_card .ant-divider-horizontal {
	margin: 10px;
}

.condition_card small {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
	margin: 0;
	padding: 0;
	margin-bottom: 10px;
	color: #0b5394;
}

.condition_card label {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
	margin: 0;
	padding: 0;
	margin-bottom: 10px;
}

.condition_list {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #e5e7eb;
	margin: 0;
	padding: 0;
	right: 15px;
	height: 80px;
	border-radius: 0 !important;
}

.spv_updates p {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}
.spv_updates h2 {
	font-family: Manrope;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0.01em;
	text-align: left;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 0;
	padding: 0;
}
.spv_term_list h2 {
	color: #6b7280;

	display: flex;
	flex-direction: column;

	margin: 0;
	padding: 0;
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.spv_term_list h3 {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.spv_term_list {
	margin-bottom: 10px;
	border-bottom: 1px solid #e5e7eb;
}
.spv_list .ant-list-grid .ant-col > .ant-list-item {
	display: block;
	max-width: 100%;
	margin-bottom: 16px;
	padding-top: 0;
	padding-bottom: 0;
	border-bottom: none;
	margin: 0;
}
.tag_status {
	background: #fef3c7;
	color: #92400e;
}

/* .profile-tab-custom .ant-tabs-tab-active {
	color: red !important;
} */
.profile-tab-custom .ant-tabs-tab.ant-tabs-tab-active {
	border-bottom: 2px solid #0b5394 !important;
	z-index: 2;
}

.profile-tab-custom .ant-tabs-nav {
	border-bottom: 1px solid #e5e7eb !important;
	z-index: 2;
}

.profile-tab-custom .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.01em;
	color: #0b5394;
}
.btn_comment {
	height: 38px;
	width: 99px;
	border-radius: 8px;
	padding: 9px, 16px, 9px, 16px;
	background: #0b5394;
	color: #fff;
	float: right;
}

.share_btn {
	width: 15px !important;
	height: 15.83px !important;
}

.dt_comment {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.dt_comment small {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.comment_list span {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.comment_list .ant-tag {
	font-family: Manrope;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-align: right;
	color: #0b5394;
	background: rgba(11, 83, 148, 0.05);
	border-radius: 12px;
	margin-left: 16px;
}

.comment-text {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}
.tag_comment {
	font-family: Manrope;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-align: left;
	color: #0b5394;
	background: rgba(11, 83, 148, 0.05);
	border-radius: 12px;
}
.comment_actions {
	display: flex;
	font-family: Manrope;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;

	color: rgb(51, 51, 51);
	cursor: pointer;
	margin-top: 8px;
}

.comment_actions small {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	/* Text: Secondary */
	color: #6b7280;
}

.reply_container {
	display: flex;
	flex-direction: row;
}
.reply_dot {
	width: 3px;
	height: 3px;
	left: 78px;
	top: 8.5px;
	padding-left: 1rem;
	padding-right: 1rem;
	/* Text: Secondary */
	color: #6b7280;
}

.reply_text {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em;
	text-decoration-line: underline;
	/* Text: Secondary */
	color: #6b7280;
}

.author_name_deals_details {
	font-family: "Manrope" !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 20px !important;
	/* identical to box height, or 143% */
	letter-spacing: 0.01em !important;
	/* Text */
	color: #111827 !important;
}
.comment-action {
	margin-right: 8px;
	font-family: Manrope;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}

.comment-action:hover {
	text-decoration: underline;
}

.cmt_form {
}
/* comment_detail span {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
	
} */
/* .comments {
	margin-top: 20px;
  }
  
  .comments-title {
	font-size: 30px;
	margin-bottom: 20px;
  }
  
  .comments-container {
	margin-top: 40px;
  }
  
  .comment-form-title {
	font-size: 22px;
  }
  
  .comment-form-textarea {
	width: 100%;
	height: 80px;
	margin-bottom: 8px;
	margin-top: 8px;
	border: 1px solid rgb(107, 114, 12);
  }
  
  .comment-form-button {
	font-size: 16px;
	padding: 8px 16px;
	background: rgb(59, 130, 246);
	border-radius: 8px;
	color: white;
  }
  
  .comment-form-button:hover:enabled {
	cursor: pointer;
	background: rgb(37, 99, 235);
  }
  
  .comment-form-button:disabled {
	opacity: 0.7;
	cursor: default;
  }
  
  .comment-form-cancel-button {
	margin-left: 10px;
  }
  
  .comment {
	display: flex;
	margin-bottom: 28px;
  }
  
  .comment-image-container {
	margin-right: 12px;
  }
  
  .comment-image-container img {
	border-radius: 50px;
  }
  
  .comment-right-part {
	width: 100%;
  }
  
  .comment-content {
	display: flex;
  }
  
  .comment-author {
	margin-right: 8px;
	font-size: 20px;
	color: rgb(59, 130, 246);
  }
  

  
  .comment-actions {
	display: flex;
	font-size: 12px;
	color: rgb(51, 51, 51);
	cursor: pointer;
	margin-top: 8px;
  }
  
  .comment-action {
	margin-right: 8px;
  }
  
  .comment-action:hover {
	text-decoration: underline;
  }
  
  .replies {
	margin-top: 20px;
  } */
.invest_page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;

	margin: 0;
	padding: 0;
	width: auto;
	height: 562px;
}
.invest_form p {
	margin-top: 2rem;
}

.profileinvest .ant-input {
	font-family: Manrope;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}
.profileinvest {
	height: 38px;
	width: auto;
	border-radius: 6px;
	display: flex;
	flex-direction: row;
}
.invest_form .ant-divider-horizontal {
	display: flex;
	clear: both;
	width: 100%;
	min-width: 100%;
	margin: 0px 0;

	/* border-bottom: 1px solid #f0f0f0; */
}
.btn_invest {
	margin: 0;
	padding: 0;
	background: #0b5394;
	border-radius: 6px;
	height: 38px;
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #fff;
}
.card_page .ant-card {
	margin: 0;
	padding: 0;
	right: 24px;
}

.card_details_deals_amount {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 36px;
	/* identical to box height, or 150% */
	letter-spacing: 0.01em;
	/* Text */
	color: #111827;
}

.days_left_count {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	letter-spacing: 0.01em;
	/* Text */
	color: #111827;
}

.overview_list:before {
	content: "•     ";
	width: 6px;
	height: 6px;
}
.intro_overview {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	/* identical to box height, or 150% */
	letter-spacing: 0.01em;
	/* Text */
	color: #111827;
}
.intro_overview_description {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* or 143% */
	letter-spacing: 0.01em;
	/* Button:Secondary */
	color: #374151;
}

.spv_updates .ant-card {
	margin: 0;
	padding: 0;
	right: 0px;
}
.top_card h4 {
	/* height: 60px;
width: 655px;
left: 32px;
border-radius: 0px; */

	font-family: Manrope;
	font-size: 24px;
	font-weight: 600;
	line-height: 36px;
	letter-spacing: 0.01em;
	text-align: left;
	margin: 0;
	margin-left: 16px;
}
.top_card p {
	font-family: Manrope;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.01em;
	text-align: left;
	color: #6b7280;
	margin: 0;
	margin-left: 16px;
}

.top_card button {
	height: 38px;
	width: 147px;

	float: right;
	border-radius: 6px;
}

.top_card .ant-avatar-group .ant-avatar-square {
	height: 60px;
	width: 60px;
	left: 0px;
	top: 0px;
	border-radius: 3px;
}

.top_card .ant-divider-vertical {
	height: 100%;
}
.step_card {
	background: #f9fafb;
}
.invest_update_card h2 {
	font-family: Manrope;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0.01em;
	text-align: left;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 0;
	padding: 0;
}

.input-field {
	width: 98% !important;
	border-radius: 6px;
}

.input-fld {
	width: 98% !important;
}
.dashboard-profile-dob {
	border-radius: 5px !important;
}

.validation_error {
	padding-bottom: 1rem !important;
}

@media (max-width: 992px){
	.ant-row{
		margin-left:0px;
		margin-right:0px;
	}
}

.logo_margin{
	margin-top:1.3em;
	margin-left:1.3em;
}

.input-style {
	padding: 9px 13px 9px 12px;
	height: 42px;
	background: #FFFFFF;
	border: 1px solid #D1D5DB;
	border-radius: 6px;
	box-shadow: none;
}

.input-style:hover {
	border: 1px solid #D1D5DB !important;
}

.input-font-style {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: #6B7280;
}

.ml-15 {
	margin-left: 15px;
}

.ovr-wrp{
	overflow-wrap: initial !important;
}

.shape-avatar-letter {
	display: flex !important;
    align-items: center !important;

	.ant-avatar-string {
		color: #0B5394 !important;
		font-size: 16px;
		font-weight: 600;
		line-height: 0px !important;
	}
}

.ant-avatar {
    background-color: #E7EEF5 !important;
}

.header-mob {
	.ant-affix {
		z-index: 1000 !important;
	}
}

.m-b-0 {
	margin-bottom: 0px !important;
}

.onboarding-row-wrapper {
	max-width: 500px;
	margin-top:40px;
    margin-bottom: 20px;
	padding: 0 15px;

	@media only screen and (max-width:768px){
		margin-top: 0px;

		p {
			text-align: center;
		}
	}

	.confirm-submit-btn {
		margin-top: 15px;
	}

	.ant-card-meta {
		align-items: center;

		.ant-card-meta-avatar {
			img {
				height: 35px;
			}
		}
	}
}

.onboarding-wrapper {

	@media only screen and (max-width:768px){
		.logo-image-mobile {
			display: flex !important;
		}
	}
}

.main-onboarding-wrapper {
	height: 100vh;
	overflow: auto;

	@media only screen and (max-width:768px){
		.logo-image-mobile {
			display: block !important;
		}
	}
}

.main-onboarding-wrapper::-webkit-scrollbar {
	width: 3px;
}
  
.main-onboarding-wrapper::-webkit-scrollbar-track {
	background: #e7eef5;
}
  
.main-onboarding-wrapper::-webkit-scrollbar-thumb {
	background: #0b5394;
}
  
.main-onboarding-wrapper::-webkit-scrollbar-thumb:hover {
	background: #0b5494a9;
}

.fac-image-selection {
	width: 49px !important;
    height: 27px !important;
}

.header-notifications-dropdown {
	max-height: 400px !important;
	width: 300px !important;
	overflow: auto;

	.right-content {
		margin-left: 5px;
	}

	.ant-list-item-meta-description {
		color: #000;
	}
}

.header-notifications-dropdown::-webkit-scrollbar {
	width: 3px;
}

.header-notifications-dropdown::-webkit-scrollbar-track {
	background: #e7eef5;
}

.header-notifications-dropdown::-webkit-scrollbar-thumb {
	background: #0b5394;
}

.header-notifications-dropdown::-webkit-scrollbar-thumb:hover {
background: #0b5494a9;
}

.ant-popover {
	&.ant-popover-placement-top {
		position: fixed;
	}
}

.flex-1 {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.d-flex-card {
	display: flex !important;
	.ant-card-body{
		flex: 1;
	}
}

.filter-btn-container-irm{
	display: flex !important;
	align-items: center !important;
	justify-content: flex-end !important;
	padding-right: 20px !important;
}

.capitalize{
	text-transform: capitalize;
}

.custom-required{
	label::after{
	  display: block !important;
	  margin-right: 4px;
	  color: #ff4d4f;
	  font-size: 14px;
	  font-family: SimSun, sans-serif;
	  line-height: 1;
	  content: "*" !important;
	}
  }
@primary-color: #0B5394;