.deal-detail {
  .message-button {
    margin-left: 5px;
  }

  @media (max-width: 767px) {
    .ant-row {
      display: flex !important;
    }
  }

  .org-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .card-header {
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    width: 100%;
  }

  .card_details_deals_amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card_detail_price {
    display: flex;
  }

  .top-title {
    display: flex;
    align-items: center;

    &_title {
      font-family: Manrope;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0.01em;
      text-align: left;
      margin: 0;
      margin-right: 10px;
    }
  }

  .ant-list-item-action {
    display: flex;
    align-items: center;
    padding: 0 5px !important;
  }

  .ant-btn-link {
    &.alow {
      color: #16b364;
    }
  }

  .ant-btn {
    top: 0px;
    border-radius: 6px;
    padding: 9px, 17px, 9px, 17px;
    border-radius: 6px;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  .ant-btn-primary {
    border-color: #0b5394;
    background: #0b5394;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  .ant-btn-text {
    color: #16b364;
    background-color: transparent !important;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  .description {
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
  }

  .deal-logo {
    margin-right: 16px;

    .ant-image {
      border-radius: 15px;
    }
  }

  .header-btns {
    width:100%;
    &__share {
      width: 52px;
      height: 38px;
    }


    &__invest {
      height: 38px;
      // background: #0b5394;
      // color: white;
    }

    .ant-space-item {
      display: flex;
      width: 100%;
      button { 
        width:100%;
      }
    }
    
  }

  .deal-intro {
    margin-top: 15px;
    margin-bottom: 15px;

    .intro-video {
      display: flex;
      flex-direction: column;
      width: auto !important;
      margin-bottom: 15px;
    }
  }

  .deal-intro-list-ext {
    > div {
      // height: 80px;
    }

    .list-item {
      height: auto !important;
    }

    .ant-list-item {
      margin-bottom: 0;
    }

    .ant-card-body {
      padding: 14px 24px;
    }
  }

  .deal-intro-list {
    .ant-row {
      display: flex;
    }

    .list-item {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #e5e7eb;
      align-items: justify;
      height: 100px;
      border-radius: 0 !important;

      &__value {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #111827;
      }
    }
  }

  .team-tab {
    .ant-row {
      display: flex;
    }

    .team-card {
      &__avatar {
        margin-right: 10px;
      }

      &__name {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }

      &__position {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #6b7280;
      }
    }
  }
}

.deal-details-tab {
  .ant-steps-item-container {
    display: flex;
    align-items: center;

    .ant-steps-item-tail {
      position: absolute !important;
      top: 17px !important;
      left: 19px !important;
    }
  }
}

.manage_deal_type {
  margin-left: 20px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.dd-value {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #111827;
}

.dd-label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  margin-bottom: 0px;
}

.deal-details-header-row {
  @media (max-width: 767px) {
    .ant-row {
      margin-left: 0px !important;
      margin-right: 0px !important;
      display: flex !important;
    }
  }
}

.ant-modal-confirm-body-wrapper {
  .ant-modal-confirm-btns {
    text-align: center;
  }
}


.ant-layout-sider {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;

  .ant-menu-item {
    margin: 0 !important;
    
    &:hover {
      background-color: #f0f2f5;
    }
  }
}

.ant-layout-content {
  background: #fff;
}