.bottom-alert {
  background-color: #0B5394; /* Dark blue theme */
  color: white;
  padding: 12px 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.fade-in {
  opacity: 1;
  transform: scale(1);
}

.fade-out {
  opacity: 0;
  transform: scale(0.9);
}

.close-btn {
  font-size: 18px;
  cursor: pointer;
  color: white;
  transition: color 0.3s;
  margin-left: 12px;
}

.close-btn:hover {
  color: #ff6b6b; /* Light red on hover */
}
